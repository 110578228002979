import request from '@/utils/request.js'

// 学校列表
export const listbyparams = (query) => {
  return request({
    method: 'post',
    url: '/system/college/listbyparams',
    data: query
  })
}

// 根据学校编号查询学校
export const collegequeryById = (query) => {
  return request({
    method: 'post',
    url: '/system/college/queryById',
    data: query
  })
}
//  根据collengNumber查询 优势专业
export const REQcollegemajor = (query) => {
  return request({
    method: 'post',
    url: '/system/dominantmajor/queryByparam',
    data: query
  })
}
//  根据collengNumber查询 分数计划  /dev/api/system/scoreplan/queryByparam
export const REQcollegescore = (query) => {
  return request({
    method: 'get',
    url: '/system/scoreplan/queryByparam',
    params: query
  })
}
//  根据collengNumber查询 招生简章  /dev/api/system/enrollmentguide/queryByparam
export const REQenrollmentguide = (query) => {
  return request({
    method: 'post',
    url: '/system/enrollmentguide/queryByparam',
    data: query
  })
}
// 根据conllegenumber查询分数计划
export const REQqueryPlanList = (query) => {
  return request({
    method: 'get',
    url: '/system/enrollment/queryPlanList',
    params: query
  })
}
// 根据connegeNumber查询录取分数
export const REQqueryBatchScore = (query) => {
  return request({
    method: 'get',
    url: '/system/enrollment/queryNewBatchScore',
    params: query
  })
}
// 招生计划--明细查询
export const REQqueryCollegePlanList = (query) => {
  return request({
    method: 'post',
    url: '/system/scoreplan/queryCollegePlanList',
    data: query
  })
}
// 获取院校优势专业
export function getYszyDetail(query) {
  return request({
    method: 'get',
    url: "/system/advantage/queryAdvantageMajor",
    params: query,
  })
}
