<template id="temp">
  <div>
      <div class="screen" ref="screenh">
          <div class="condition" v-for="(item, index) in screenarr" :key="index">
            <div class="searchTitle">
              {{ item.title }}
            </div>
            <div style="flex: 47">
              <span @click="active(index, indexitem)" v-for="(itemitem, indexitem) in item.list" :key="indexitem"
                :class="{ active: activerarr[screenarr[index].type].indexOf(itemitem.id) != -1 }">{{
                  itemitem.name }}</span>
            </div>
          </div>
        </div>
        <div class="wrap">
          <div class="wrap_left">
            <div class="condition" style="align-items: center;">
              <div class="searchTitle" style="flex:10">
                共匹配到<span style="color: #12B098;">{{ queryParams.total }}</span>条数据
              </div>
              <div class="searchInput" style="width: 500px;">
                <el-input v-model="collegeName" @keyup.enter.native="sousuo"
                  placeholder="搜文章/找大学/查专业/看职业/搜课程"></el-input>
                <div class="divicon" @click="sousuo()">
                  <i class="el-icon-search" style="color: white;"></i>
                </div>
              </div>
               <div class="resetBtnText" @click="reset" style="margin: 0px 0 0 10px">重置</div>
            </div>
            <div class="collegeslist" style="width: 100%;" :style="{ '--screenhight': screenhight }">
            <div v-loading="loading" element-loading-text="正在加载中,请稍后">
              <div class="collegelistFather" v-for="(item, index) in collegelist" :key="index"
                @click="detailbtn(item.collegeNumber)">
                <div class="collegelist">
                  <img :src="item.collegeLogo" width="94px" height="94px" referrerpolicy="no-referrer" />

                  <div style="margin-left: 20px">
                    <div style="
                            font-weight: bold;
                            font-size: 16px;
                          ">
                      {{ item.collegeName }}
                    </div>
                    <div style="margin: 7px 0;">
                      <i class="el-icon-location-outline" style="color: #12B098;"></i>
                      <span style="font-size: 14px;color: #686868;margin-left: 7px;">{{ item.collegeProvince }} {{ item.collegeLocality }}</span>
                    </div>
                    <div>
                      <span class="TypeandNature" style="margin-left:0rpx">{{ item.collegeType }}</span>
                      <span class="TypeandNature">{{ item.collegeNature }}</span>
                    </div>
                  </div>
                  <!-- 定位的图片 -->
                  <img src="../assets/imgs/schooldir.png" style="position: absolute;right:-40px;" alt="">
                </div>
              </div>
              <el-empty description="暂无院校" v-show="queryParams.total == 0"></el-empty>

            </div>
          </div>
          <pagination v-show="queryParams.total > 1 && loading == false" :total="queryParams.total"
                  :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="onload()" />
          </div>
          <div class="wrap_right resourceAndInformation">
            <img src="../assets/imgs/schoolright.png" @click="goMBti" alt="">
            <hotSchool type="2"></hotSchool>
          </div>
        </div>
  </div>
</template>

<script>
import { listbyparams } from "@/api/checkschools.js";
import hotSchool from "@/components/hotSchool.vue"
export default {
  name: "checkschools",
  components: {
    hotSchool,
  },
  props:['activeId'],
  data() {
    return {
      screenarr: [
        {
          title: "院校所属",
          type: "collegeProvince",
          list: [
            { id: 0, name: "全部", ProSort: 1, ProRemark: "直辖市" },
            { id: 1, name: "北京市", ProSort: 1, ProRemark: "直辖市" },
            { id: 2, name: "天津市", ProSort: 2, ProRemark: "直辖市" },
            { id: 3, name: "河北省", ProSort: 5, ProRemark: "份" },
            { id: 4, name: "山西省", ProSort: 6, ProRemark: "份" },
            { id: 5, name: "内蒙古自治区", ProSort: 32, ProRemark: "自治区" },
            { id: 6, name: "辽宁省", ProSort: 8, ProRemark: "份" },
            { id: 7, name: "吉林省", ProSort: 9, ProRemark: "份" },
            { id: 8, name: "黑龙江省", ProSort: 10, ProRemark: "份" },
            { id: 9, name: "上海市", ProSort: 3, ProRemark: "直辖市" },
            { id: 10, name: "江苏省", ProSort: 11, ProRemark: "份" },
            { id: 11, name: "浙江省", ProSort: 12, ProRemark: "份" },
            { id: 12, name: "安徽省", ProSort: 13, ProRemark: "份" },
            { id: 13, name: "福建省", ProSort: 14, ProRemark: "份" },
            { id: 14, name: "江西省", ProSort: 15, ProRemark: "份" },
            { id: 15, name: "山东省", ProSort: 16, ProRemark: "份" },
            { id: 16, name: "河南省", ProSort: 17, ProRemark: "份" },
            { id: 17, name: "湖北省", ProSort: 18, ProRemark: "份" },
            { id: 18, name: "湖南省", ProSort: 19, ProRemark: "份" },
            { id: 19, name: "广东省", ProSort: 20, ProRemark: "份" },
            { id: 20, name: "海南省", ProSort: 24, ProRemark: "份" },
            {
              id: 21,
              name: "广西壮族自治区",
              ProSort: 28,
              ProRemark: "自治区",
            },
            { id: 22, name: "甘肃省", ProSort: 21, ProRemark: "份" },
            { id: 23, name: "陕西省", ProSort: 27, ProRemark: "份" },
            {
              id: 24,
              name: "新疆维吾尔自治区",
              ProSort: 31,
              ProRemark: "自治区",
            },
            { id: 25, name: "青海省", ProSort: 26, ProRemark: "份" },
            {
              id: 26,
              name: "宁夏回族自治区",
              ProSort: 30,
              ProRemark: "自治区",
            },
            { id: 27, name: "重庆市", ProSort: 4, ProRemark: "直辖市" },
            { id: 28, name: "四川省", ProSort: 22, ProRemark: "份" },
            { id: 29, name: "贵州省", ProSort: 23, ProRemark: "份" },
            { id: 30, name: "云南省", ProSort: 25, ProRemark: "份" },
            { id: 31, name: "西藏自治区", ProSort: 29, ProRemark: "自治区" },
            { id: 32, name: "台湾省", ProSort: 7, ProRemark: "份" },
            {
              id: 33,
              name: "澳门特别行政区",
              ProSort: 33,
              ProRemark: "特别行政区",
            },
            {
              id: 34,
              name: "香港特别行政区",
              ProSort: 34,
              ProRemark: "特别行政区",
            },
          ],
        },
        {
            title:'院校层次',
            type:'collegeSenior',
            list:[
              {name:'本科',id:0},
              {name:'专科',id:1},
            ]
        },
        {
          title: "院校类型",
          type: "collegeGenre",
          list: [
            { name: "全部", id: 0 },
            { name: "综合", id: 1 },
            { name: "理工", id: 2 },
            { name: "财经", id: 3 },
            { name: "农林", id: 4 },
            { name: "医药", id: 5 },
            { name: "师范", id: 6 },
            { name: "体育", id: 7 },
            { name: "政法", id: 8 },
            { name: "艺术", id: 9 },
            { name: "民族", id: 10 },
            { name: "军事", id: 11 },
            { name: "语言", id: 12 },
          ],
        },
        {
          title:'院校性质',
          type:'collegeXz',
          list:[
             { name: "全部", id: 0 },
            { name: "公办", id: 1 },
            { name: "民办", id: 2 },
            { name: "中外合作办学", id: 3 },
            { name: "内地与港澳合作办学", id: 4 },
          ],
        },
        {
          title: "院校特色",
          type: "collegeType",
          list: [
            { name: "全部", id: 0 },
            { name: "985", id: 1 },
            { name: "211", id: 2 },
            { name: "双一流", id: 3 },
            { name: "省重点", id: 4 },
             { name: '国重点', show: 5 },
            { name: "教育部直属", id: 6 },
            { name: "中央部委直属", id: 7 },
          ],
        },
      ],
      collegeName: "",
      activerarr: {
        collegeProvince: [0],
        collegeSenior:[0],
        collegeGenre: [0],
        collegeXz:[0],
        collegeType: [0],
      },
      loading: true,
      screenhight: 0,
      collegelist: [],
      queryParams: {
        total: 1,
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.collegeName = this.$route.query.collegeName ? this.$route.query.collegeName : ''
    this.onload();
  },
  mounted() {
    this.screenhight = this.$refs.screenh.offsetHeight + 30 + 20 + 65 + 215 + "px";
  },
  methods: {
    active(index, indexitem) {
      if (this.screenarr[index].list[indexitem].id == 0) {
        this.activerarr[this.screenarr[index].type] = [0];
      } else {
        var sameid = "";
        if (this.activerarr[this.screenarr[index].type].indexOf(this.screenarr[index].list[indexitem].id) != -1) {
          sameid = this.screenarr[index].list[indexitem].id;
        } else {
          sameid = "0";
        }
        var arr = JSON.parse(
          JSON.stringify(this.activerarr[this.screenarr[index].type])
        );
        for (var i = 0; i < arr.length; i++) {
          if (String(arr[i]) == sameid) {
            arr.splice(i, 1);
          }
        }
        this.activerarr[this.screenarr[index].type] = arr;
        if (sameid == "0") {
          const arr = ['collegeProvince','collegeGenre','collegeType','collegeSenior','collegeXz']
          // 如果院校所属和院校类型是多选  就注掉if 保留else里的
          if (  arr.includes(this.screenarr[index].type) ) {
            this.activerarr[this.screenarr[index].type] = [];
            this.activerarr[this.screenarr[index].type].push(this.screenarr[index].list[indexitem].id);
          } else {
            this.activerarr[this.screenarr[index].type].push(this.screenarr[index].list[indexitem].id);
          }
        } else if (this.activerarr[this.screenarr[index].type].length == 0) {
          this.activerarr[this.screenarr[index].type] = [0];
        }
      }
      this.sousuo();
    },
    onload() {
      this.loading = true;
      this.collegelist = [];
      var params = JSON.parse(JSON.stringify(this.activerarr));
      // 省份
      for (var i = 0; i < params.collegeProvince.length; i++) {
        for (var j = 0; j < this.screenarr[0].list.length; j++) {
          if (this.screenarr[0].list[j].id == params.collegeProvince[i]) {
            params.collegeProvince[i] = this.screenarr[0].list[j].name;
          }
        }
      }
      // 层次
       for (var i = 0; i < params.collegeSenior.length; i++) {
        for (var j = 0; j < this.screenarr[1].list.length; j++) {
          if (this.screenarr[1].list[j].id == params.collegeSenior[i]) {
            params.collegeSenior[i] = this.screenarr[1].list[j].name;
          }
        }
      }
      // 综合、理工
      for (var i = 0; i < params.collegeGenre.length; i++) {
        for (var j = 0; j < this.screenarr[2].list.length; j++) {
          if (this.screenarr[2].list[j].id == params.collegeGenre[i]) {
            params.collegeGenre[i] = this.screenarr[2].list[j].name;
          }
        }
      }
      // 公办、民办、中午合作办学
       for (var i = 0; i < params.collegeXz.length; i++) {
        for (var j = 0; j < this.screenarr[3].list.length; j++) {
          if (this.screenarr[3].list[j].id == params.collegeXz[i]) {
            params.collegeXz[i] = this.screenarr[3].list[j].name;
          }
        }
      }
      // 985、211
      for (var i = 0; i < params.collegeType.length; i++) {
        for (var j = 0; j < this.screenarr[4].list.length; j++) {
          if (this.screenarr[4].list[j].id == params.collegeType[i]) {
            params.collegeType[i] = this.screenarr[4].list[j].name;
          }
        }
      }
      let collegeGenres = [];
      let collegeProvinces =[];
      collegeGenres.push(params.collegeGenre.toString())
      collegeProvinces.push(params.collegeProvince.toString())

      let obj = {
        collegeGenres: collegeGenres,  //【综合、理工、农林】
        collegeLabel: params.collegeType.toString(),   //双一流 985 211
        collegeName: this.collegeName,
        collegeProvinces: collegeProvinces,   //省份
        collegeType:params.collegeSenior[0],   //本科、专科
        collegeNature:params.collegeXz[0], //公办、民办、中外合作办学
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize
      };
      // obj.collegeProvinces = params.collegeProvince.toString();
      // obj.collegeGenres = params.collegeGenre.toString();
      // obj.collegeLabel = params.collegeType.toString();
      // obj.collegeName = this.collegeName;
      // obj.pageNum = this.queryParams.pageNum;
      // obj.pageSize = this.queryParams.pageSize;
      var that = this;
      if(obj.collegeNature == '全部'){
          obj.collegeNature= ''
      }

      listbyparams(obj).then((res) => {
        that.collegelist = res.data.rows;
        that.queryParams.total = res.data.total;
        that.loading = false;
      });
    },
    sousuo() {
      this.queryParams.pageNum = 1;
      this.onload();
    },
    reset() {
      this.collegeName = ""
             this.activerarr = {
        collegeProvince: [0],
        collegeSenior:[0],
        collegeGenre: [0],
        collegeXz:[0],
        collegeType: [0],
      }
        this.queryParams = {
          total: 1,
          pageNum: 1,
          pageSize: 10,
        };
      this.onload();
    },
    detailbtn(collegeNumber) {
      this.$router.push({ path: "/profile", query: { collegeNumber: collegeNumber,activeId:this.activeId } });
    },
    goMBti(){
      const phonenumber = localStorage.getItem('phonenumber')
      if(!phonenumber){
        const channel = new BroadcastChannel('share')
              channel.postMessage(true)
              // this.$message.warning('请先登录')
          return
      }
      this.$router.push({ path: "/takeatestItem",query: { type: 2 }});

    }
  },
};
</script>

<style scoped lang="scss">
.checkschools {
  box-sizing: border-box;
  width: 100%;
  // background-color: #fcfbf9;
  padding: 10px 0 80px 0;

  .searchInput {
    width: 627px;
  }

  .screen {
    box-sizing: border-box;
    // padding: 35px 30px;
    width: 100%;
    // border: 1px solid #f4f4f4;
    border-radius: 10px;
    background: white;
    user-select: none;




  }

  .condition {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .searchTitle {
      font-size: 16px;
      color: #000000;
      margin-right: 15px;
      flex: 3.5;
      line-height: 25px;
    }

    .active {
      background: #12B098;
      border-radius: 8px;
      color: white;
    }

    .active:hover {
      color: white;
    }

    span {
      cursor: pointer;
      display: inline-block;
      margin-right: 9px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #5F5F5F;
      box-sizing: border-box;
      padding: 3px 8px;
      height: 27px;
      line-height: 21px;
    }

    span:hover {
      color: #12B098;
    }
  }

  .wrap {
    display: flex;

    .wrap_left {
      flex: 1;
      margin-right: 74px;
    }

    .wrap_right {
      width: 300px;
    }
  }

  .collegeslist {
    box-sizing: border-box;
    min-height: calc(1080px - var(--screenhight));
    width: 100%;
    margin-top: 20px;
    padding: 0 35px 20px 35px;
    // border: 1px solid #f4f4f4;
    border-radius: 10px;
    background: white;

    ::v-deep .el-loading-spinner {
      margin-top: 100px;
      top: 0;
    }

    ::v-deep .el-loading-spinner .path {
      stroke: #30C9B2;
    }

    ::v-deep .el-loading-spinner .el-loading-text {
      color: #30C9B2;
    }

    .collegelistFather {
      display: inline-block;
      width: 43%;
      cursor: pointer;
    }

    .collegelistFather:nth-child(odd) {
      margin-right: 105px;
    }

    .collegelist {
      width: 100%;
      height: 130px;
      // border-bottom: 2px solid #f2f2f2;
      display: flex;
      position: relative;
      align-items: center;


      .details {
        width: 80px;
        height: 35px;
        border-radius: 5px;
        border: 2px solid #30C9B2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
      }

      .TypeandNature {
        color: #30cab2;
        background: white;
        border: 2px solid #30cab2;
        padding: 2px 7px;
        border-radius: 15px;
        display: inline-block;
        margin: 0 3px;
        font-size: 12px;
      }
    }
  }
}</style>
