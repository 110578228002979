<template>
  <!--  :style="{ height: type == '1' ? '690px' : '535px' }" -->
  <div class="right_school" >
    <div class="schooltitle">
      <span style="font-size: 22px">热门院校</span>
      <!-- <span style="margin:0px 12px 0px 22px;font-size: 18px;">本科</span> -->
      <!-- <span style="font-size: 14px;">专科</span> -->
    </div>
    <div class="schoollist">
      <div class="schoolItem indexFzTwo" v-for="(item, index) in schoollist" :key="index" @click="handiclick(item)">
            <img v-if="index == 0" src="../assets/imgs/first.png" alt="">
            <img v-if="index == 1" src="../assets/imgs/second.png" alt="">
            <img v-if="index == 2" src="../assets/imgs/third.png" alt="">
            <span class="schoolhost" v-if="index > 2">{{ index + 1 }}</span>
            <span class="schoolName">{{ item.schoolName }}</span>
            <span class="schoollevel">{{ item.schoollevel }}</span>
      </div>
    </div>
    <div class="schoolCircle">
      <!-- <div class="circle"></div>
      <div class="circle"></div> -->
    </div>
  </div>
</template>

<script>
import { queryCollegeBcuRank } from '../api/resourceAndconsult'
export default {
  name: 'hotSchool',
  props: ['type'],
  data() {
    return {
      schoollist: [

      ],
    }
  },
  methods: {
    handiclick(item) {
      this.$router.push({
        path: '/profile',
        query: {
          collegeNumber: item.collegeNumber
        }
      })
    }
  },
  mounted() {

    queryCollegeBcuRank({ collegeType: "本科" }).then(res => {
      // 如果是首页的热门院校，则截取12个。如果是查学校的热门院校，则截取10个。
      let arry =  this.type == 1 ? res.rows.splice(0, 12) : res.rows.splice(0, 10)
      this.schoollist = arry.map(item => {
        return {
          ...item,
          schoolName: item.collegeName,
          schoollevel: item.collegeType
        }
      })

    })
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/reousrces.scss";
.schoolItem{
  img{
    height: 75%!important;
  }
}
</style>
